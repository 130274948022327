import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './HeaderStyles.css';

const Header = () => {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isHome = location.pathname === '/';

  // Estilos para la opción activa
  const linkClass = (path) => 
    `text-lg ${location.pathname === path ? 'text-blue-600 border-b-2 border-blue-500' : (isHome ? 'text-white' : 'text-gray-700')} hover:text-blue-500 transition-transform duration-200 transform hover:scale-110`;

  return (
    <header className={`flex justify-between items-center p-4 ${isHome ? 'bg-transparent' : 'bg-white shadow-md'}`}>
      <div className="flex items-center space-x-4">
        <Link to="/" className="flex items-center ml-10">
          <img src="/assets/logo.png" alt="Logo" className="h-12 w-12" />
          <span className={`ml-3 text-xl font-semibold ${isHome ? 'text-white' : 'text-gray-800'}`}>PH DRYWALL</span>
        </Link>
      </div>

      <nav className={`flex items-center space-x-8 ${isMobile ? 'hidden' : ''}`}>
        <Link to="/" className={linkClass('/')}>Home</Link>
        <Link to="/about-us" className={linkClass('/about-us')}>About Us</Link>
        <Link to="/image-gallery" className={linkClass('/image-gallery')}>Image Gallery</Link>
        <Link to="/contact" className={linkClass('/contact')}>Contact Us</Link>
      </nav>

      <div className="flex items-center">
        {isMobile && (
          <div className="relative">
            <button
              className="hamburger-button"
              onClick={toggleMenu}
              aria-expanded={isOpen}
              aria-label="Toggle menu"
            >
              <span className={`block hamburger-line ${isOpen ? 'open' : ''}`}></span>
              <span className={`block hamburger-line ${isOpen ? 'open' : ''}`}></span>
              <span className={`block hamburger-line ${isOpen ? 'open' : ''}`}></span>
            </button>
            {isOpen && (
              <div className="absolute right-0 top-full mt-2 w-48 bg-white text-gray-700 rounded-lg shadow-lg z-20">
                <Link to="/" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/' ? 'bg-gray-200' : ''}`}>Home</Link>
                <Link to="/about-us" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/about-us' ? 'bg-gray-200' : ''}`}>About Us</Link>
                <Link to="/image-gallery" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/image-gallery' ? 'bg-gray-200' : ''}`}>Image Gallery</Link>
                <Link to="/contact" className={`block px-4 py-2 hover:bg-gray-100 ${location.pathname === '/contact' ? 'bg-gray-200' : ''}`}>Contact Us</Link>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default React.memo(Header);
