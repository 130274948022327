import React from 'react';
import Routes from './Routes';
import './App.css';

const App = () => {
  return <Routes />;
};

export default App;

/* 

https://dribbble.com/shots/24146526-Login-Page-Saas-Product











 */