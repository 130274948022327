// src/Routes.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';

const Home = lazy(() => import('./components/home/Home'));
const AboutUs = lazy(() => import('./pages/AboutUsPage'));
const ImageGalleryPage = lazy(() => import('./pages/ImageGalleryPage'));
const ContactUs = lazy(() => import('./components/ContactUs'));

const AppRoutes = () => {
    return (
        <Router>
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about-us" element={<AboutUs />} />
                        <Route path="/image-gallery" element={<ImageGalleryPage />} />
                        <Route path="/contact" element={<ContactUs />} />
                    </Routes>
                </Suspense>
            </Layout>
        </Router>
    );
};

export default AppRoutes;
