// src/components/Layout.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { motion } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';
import './Layout.css'; // Asegúrate de tener los estilos necesarios

const Layout = ({ children }) => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const [isVisible, setIsVisible] = useState(false);

    // Mostrar el botón cuando la página esté desplazada hacia abajo
    const toggleVisibility = () => {
        if (window.pageYOffset > 700) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Desplazarse hacia arriba suavemente
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <div className={`flex flex-col min-h-screen ${isHomePage ? 'background-image' : 'bg-white'}`}>
            <Header />
            <motion.main
                className="flex-grow"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.75, ease: "easeOut" }}
            >
                {children}
            </motion.main>
            <Footer />
            {isVisible && (
                <div className="fixed bottom-8 right-8 z-50">
                    <button
                        onClick={scrollToTop}
                        className="p-2 rounded-full bg-boton text-white shadow-lg hover:bg-boton-dark transition duration-300"
                        aria-label="Scroll to top"
                    >
                        <FaArrowUp />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Layout;
